/**
 * 固定ページ内の記事一覧
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import isNil from "lodash/isNil"

import Teaser from "../tbpost/teaser"

const READ_MORE_THREASHOLD_COUNT = 3

const PostList = ({ postArea = null, posts }) => {
  // `postArea` は `null` や `undefined` のことがある
  if (isNil(postArea)) {
    return ``
  }

  // 件数 0 件の場合は見出しも含めて何も表示しない
  if (posts.nodes.length < 1) {
    return ``
  }

  return (
    <section className="pc:mb-16 tablet_sp:mb-8">
      <h2>{postArea.heading_label}</h2>

      <div>
        {posts.nodes.map((post) => (<Teaser key={post.path} post={post} />))}
      </div>

      {(posts.pageInfo.totalCount > READ_MORE_THREASHOLD_COUNT) ? (
        <div className="text-right">
          <Link to={postArea.path} className="no-underline">一覧を見る</Link>
        </div>
      ) : ``}
    </section>
  )
}

PostList.propTypes = {
  postArea: PropTypes.exact({
    heading_label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  posts: PropTypes.exact({
    nodes: PropTypes.array.isRequired,
    pageInfo: PropTypes.exact({
      totalCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export default PostList
