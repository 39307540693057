/**
 * ホームページ（パス: `/` ）
 *
 * slug が `home` のページが非公開の場合も `/` ページは生成したいので例外的に扱う
 */
import * as React from "react"
import { graphql } from "gatsby"

import HeaderImage from "../components/tbpage/header-image"
import Layout from "../components/layout.js"
import Markdown from "../components/markdown"
import PostList from "../components/tbpage/post-list"
import ProductList from "../components/tbpage/product-list"

const Page = ({ data }) => {
  const {
    site: { siteMetadata: { description } },
    node,
    posts_first,
    posts_second,
    products_first,
    header_image_pc,
    header_images_sp,
  } = data

  const customHeader = (
    <div className="pc:my-4 tablet_sp:my-3">
      <HeaderImage
        pcImage={header_image_pc}
        spImages={header_images_sp.nodes}
      />
    </div>
  )

  // 万が一 CMS で `home` ページが public = false に設定された場合は
  // `node === null` になるのでそのときにエラーが出ないようにする
  if (node === null) {
    return (
      <Layout
        isFront={true}
        description={description}
        CustomHeader={customHeader}
      >
      </Layout>
    )
  }

  const {
    post_area_first,
    post_area_second,
    product_category_first,
  } = node

  return (
    <Layout
      isFront={true}
      description={node?.meta_description || description}
      keywords={node?.meta_keywords || ``}
      CustomHeader={customHeader}
    >
      <PostList postArea={post_area_first} posts={posts_first} />
      <section className="pc:mb-16 tablet_sp:mb-8">
        {/* `Markdown` の `children` が必ず `string` になるようにする */}
        <Markdown>{node?.body || ``}</Markdown>
      </section>
      <PostList postArea={post_area_second} posts={posts_second} />
      <ProductList productCategory={product_category_first} products={products_first} />
    </Layout>
  )
}

export default Page

// CMS でセットされた `title` は使用しない
export const query = graphql`
  query HomeQuery(
    $path: String!,
    $post_area_first: String,
    $post_area_second: String,
    $product_category_first: String,
    $header_image_dir: String!,
  ) {
    site {
      siteMetadata {
        description
      }
    }

    node: tbPage(path: { eq: $path }) {
      ogp_image {
        publicURL
      }
      meta_description
      meta_keywords
      file {
        publicURL
      }
      body
      post_area_first {
        path
        heading_label
      }
      post_area_second {
        path
        heading_label
      }
      product_category_first {
        path
        heading_label
      }
    }

    # 記事一覧（上部）
    posts_first: allTbPost(
      filter: {post_areas: {elemMatch: {machine_name: {eq: $post_area_first}}}},
      limit: 3,
      sort: {fields: [date, cms_id], order: [DESC, DESC]}
    ) {
      nodes {
        ...TbPostTeaser
      }
      pageInfo {
        totalCount
      }
    }

    # 記事一覧（下部）
    posts_second: allTbPost(
      filter: {post_areas: {elemMatch: {machine_name: {eq: $post_area_second}}}},
      limit: 3,
      sort: {fields: [date, cms_id], order: [DESC, DESC]}
    ) {
      nodes {
        ...TbPostTeaser
      }
      pageInfo {
        totalCount
      }
    }

    # 商品一覧
    products_first: allTbProduct(
      filter: {product_categories: {elemMatch: {machine_name: {eq: $product_category_first}}}}
      limit: 10,
      sort: {fields: [order_weight, cms_id], order: [ASC, DESC]}
    ) {
      nodes {
        ...TbProductTeaser
      }
      pageInfo {
        totalCount
      }
    }

    # ヘッダー画像（ PC ）
    header_image_pc: file(
      relativeDirectory: {eq: $header_image_dir},
      name: {eq: "P1"},
      internal: {mediaType: {glob: "image/*"}}
      sourceInstanceName: {eq: "images"}
    ) {
      src: publicURL
    }

    # ヘッダー画像（ SP / Tablet ）
    header_images_sp: allFile(
      filter: {
        relativeDirectory: {eq: $header_image_dir}
        name: {glob: "S*"}
        internal: {mediaType: {glob: "image/*"}}
        sourceInstanceName: {eq: "images"}
      }
      sort: {order: ASC, fields: name}
    ) {
      nodes {
        src: publicURL
      }
    }
  }
`
