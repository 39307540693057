/**
 * 固定ページ内の商品一覧
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import isNil from "lodash/isNil"

import Teaser from "../tbproduct/teaser"
import BorderedHeading from "./bordered-heading"

const READ_MORE_THREASHOLD_COUNT = 10

const ProductList = ({ productCategory = null, products }) => {
  // `productCategory` は `null` や `undefined` のことがある
  if (isNil(productCategory)) {
    return ``
  }

  // 件数 0 件の場合は見出しも含めて何も表示しない
  if (products.nodes.length < 1) {
    return ``
  }

  return (
    <section className="pc:mb-16 tablet_sp:mb-8">
      <BorderedHeading>{productCategory.heading_label}</BorderedHeading>

      <div className="flex flex-row flex-wrap justify-start items-start">
        {products.nodes.map((product) => (
          <Teaser key={product.path} product={product} />
        ))}
      </div>

      {(products.pageInfo.totalCount > READ_MORE_THREASHOLD_COUNT) ? (
        <div className="text-right">
          <Link to={productCategory.path} className="no-underline">一覧を見る</Link>
        </div>
      ) : ``}
    </section>
  )
}

ProductList.propTypes = {
  productCategory: PropTypes.exact({
    heading_label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  products: PropTypes.exact({
    nodes: PropTypes.array.isRequired,
    pageInfo: PropTypes.exact({
      totalCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export default ProductList
