/**
 * 商品の一覧ページや一覧ブロックのティーザー
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import isNil from "lodash/isNil"

import truncate from "../../utils/truncate"

const TITLE_MAX_LENGTH = 30
const DESC_MAX_LENGTH = 70

const Teaser = ({ product }) => {
  const title  =truncate(TITLE_MAX_LENGTH, product.title)
  const description = truncate(DESC_MAX_LENGTH, product.meta_description)
  return (
    <Link to={product.path} className="text-black no-underline pc:w-1/5 pc:px-3 pc:mb-4 tablet_sp:w-1/2 tablet_sp:px-3 tablet_sp:mb-8">
      <div className="mx-auto" style={{
        // `margin: auto` とあわせて中央寄せを実現する
        maxWidth: `300px`,
      }}>
        <Thumbnail src={product?.thumbnail?.publicURL} alt={title} className="mb-3"/>
        <h3 className="break-words mt-0 mb-3 pc:text-lg tablet_sp:text-sm tablet_sp:leading-normal">{title}</h3>
        <div className="break-words tablet_sp:text-xs tablet_sp:leading-normal">{description}</div>
      </div>
    </Link>
  )
}

Teaser.propTypes = {
  product: PropTypes.exact({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    meta_description: PropTypes.string,
    thumbnail: PropTypes.exact({
      publicURL: PropTypes.string.isRequired,
    }),
  }),
}

// フラグメントもあわせて提供する
export const query = graphql`
  fragment TbProductTeaser on TbProduct {
    title
    path
    meta_description
    thumbnail {
      publicURL
    }
  }
`

/**
 * ヘルパー: サムネール画像
 */
const Thumbnail = ({ src, alt, className }) => {
  if (!isNil(src)) {
    return (
      <img src={src} alt={alt} className={className} width={300}
        style={{
          width: `300px`,
          aspectRatio: `1 / 1`,
          objectFit: `contain`,
        }}
      />
    )
  }

  // `src` が `null` の場合はフォールバック画像を表示する
  return (
    <StaticImage
      src="../../images/tbproduct/thumbnail.png"
      alt={alt}
      width={300}
      aspectRatio={1}
      className={className}
    />
  )
}

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
}

export default Teaser
