/**
 * ハイライト用の下ボーダー付きの見出し
 */
import * as React from "react"
import PropTypes from "prop-types"

const BorderedHeading = ({ children }) => {
  return (
    <h2 className="pb-2 border-b-2 border-headingBorder pc:mb-8 tablet_sp:mb-6">
      {children}
    </h2>
  )
}

BorderedHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
}

export default BorderedHeading
